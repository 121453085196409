export interface VideoDataElement {
  shortname: string;
  video: string;
  title: string;
  description: string;
}

export const videoData: VideoDataElement[] = [
  {
    shortname: 'boost-loyalty-program',
    video: '',
    title: 'Boost Loyalty Program',
    description:
      'Unleash the potential of engaging user experiences with our Gamified Loyalty Program template. Elevate customer interactions through dynamic levels, achievement badges, and captivating effects. Transform your platform with gamification, fostering loyalty and excitement among your audience. Try it now on stemmy.io!',
  },
  {
    shortname: 'leaderboards',
    video: 'qWhOz3-M_gg',
    title: 'Leaderboards',
    description:
      "Elevate Engagement and Encourage Friendly Competition with Interactive Leaderboards. Looking to create a dynamic and motivating environment? Our Interactive Leaderboard template does just that. Users can see their progress, compare achievements, and strive to be at the top. It's a powerful way to inspire engagement and a sense of accomplishment. Discover the potential of gamification through leaderboards at stemmy.io today!",
  },
  {
    shortname: 'daily-quests',
    video: 'pxXH1Z8b7x0',
    title: 'Daily Quests',
    description:
      'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
  },
  {
    shortname: 'thrilling-tournaments',
    video: '',
    title: 'Host Thrilling Tournaments',
    description:
      'Looking to create an electrifying gaming experience? Our platform hosts engaging tournaments where users can showcase their skills, compete for glory, and win amazing prizes. Plus, we offer exclusive gaming passes for a front-row seat to the action. Join us in uniting players, embracing competition, and celebrating victory. Get ready to play at stemmy.io!',
  },
  {
    shortname: 'for-travel',
    video: 'dZhzi2oS5A8',
    title: 'Progress Bars + Emoji for Travel',
    description:
      "Gamified progress bars, infused with delightful emojis, offer a captivating way to track user accomplishments and milestones. Spark excitement, encourage participation, and watch as your users journey towards success. Experience the impact of gamification for your company's engagement strategy at stemmy.io!",
  },
  {
    shortname: 'tamagotchi',
    video: '',
    title: 'Emoji Tamagotchi Gamification',
    description:
      'Unleash the Power of Emoji Tamagotchi: Elevate Engagement, Show Emotions, and Achieve Goals on Our Platform! Introducing our Emoji Tamagotchi gamification template. Watch as users nurture their digital companions, progressing through levels and displaying a range of emotions. Dive into the world of engaging gamification at stemmy.io today.',
  },
  {
    shortname: 'task-cards',
    video: 'Z0qahxHVyQ4',
    title: '3D Gamified Task Cards',
    description:
      'Motivate your community members by presenting them with engaging and random tasks through interactive 3D cards. As they complete tasks and earn rewards, you\'ll witness a surge in engagement and a stronger sense of community. Discover the power of gamification to create an active and motivated user base – explore stemmy.io for details!"',
  },
  {
    shortname: 'forms-and-quizzes',
    video: '',
    title: 'Gamified Forms and Quizzes',
    description:
      'Want to gather valuable insights from users in an exciting way? Our gamified forms and quizzes transform the process of filling out information into an engaging experience. Users play to answer questions, turning data collection into a captivating journey. Elevate engagement, enrich understanding, and gather insights with a touch of playfulness. Discover the potential at stemmy.io today!',
  },
  {
    shortname: 'forms-for-hr',
    video: '',
    title: 'Gamified Forms for HR',
    description:
      'Seeking a fresh approach to HR processes? Our gamified forms merge interaction with storytelling, creating a unique experience resembling a comic or novel. Users engage like never before, sharing information seamlessly while immersing themselves in a captivating narrative. Elevate HR interactions, encourage open communication, and gather valuable insights through an innovative journey. Explore the possibilities at stemmy.io now!',
  },
  {
    shortname: 'animated-emoji',
    video: 'L64mM2KBni8',
    title: 'Animated emoji',
    description:
      'Unleash the Power of Emoji Tamagotchi: Elevate Engagement, Show Emotions, and Achieve Goals on Our Platform! Introducing our Emoji Tamagotchi gamification template. Watch as users nurture their digital companions, progressing through levels and displaying a range of emotions. Dive into the world of engaging gamification at stemmy.io today.',
  },
  {
    shortname: 'interactive-3d-guide',
    video: 'MlmPaP12ze0',
    title: 'Interactive 3D Guide',
    description:
      'Introducing a groundbreaking way to onboard users – the Interactive 3D Guide template. Say goodbye to boring tutorials and hello to an immersive experience. Our 3D character navigates users through your website, making onboarding engaging, intuitive, and enjoyable. Explore the future of user guidance with gamification at stemmy.io!',
  },
  {
    shortname: 'onboarding-edutech',
    video: 'XXDRdsaIcdA',
    title: 'Gamified Onboarding for Edutech',
    description:
      'Edutech companies, are you ready to revolutionize user onboarding? Guide users through interactive challenges, earn points, and celebrate achievements. Elevate engagement, inspire exploration, and redefine education with gamification. Discover the potential at stemmy.io today!',
  },
  {
    shortname: 'onboarding-web3',
    video: 'Kv9tluDrSTQ',
    title: 'Onboarding for WEB3 companies',
    description:
      'Are you a Web3 company ready to lead in engagement? Our innovative onboarding features a dynamic character to guide users, earning points along the way. Plus, we host captivating tournaments that immerse users in Web3 technologies. Elevate user understanding, gamify learning, and make Web3 more engaging than ever before. Discover the power of interactive onboarding at stemmy.io!',
  },
  {
    shortname: 'slot-machine',
    video: 'XVFX71bKwwY',
    title: 'Slot Machine',
    description:
      'Experience the Thrill of the Slot Machine: Gamification at Its Best! Looking to add a touch of excitement to your platform? Our Slot Machine Element is here to delight your users. With the spin of the virtual reels, users can engage, win rewards, and immerse themselves in a thrilling gaming experience. Elevate user interaction, keep them entertained, and drive engagement to new heights. Explore the possibilities at stemmy.io today!',
  },
  {
    shortname: 'mini-games',
    video: 'kGjQv0yCgk8',
    title: 'Branded Mini Games',
    description:
      "Experience Branded Mini Games: Your Fun, Your Way! Looking for a unique way to connect with your audience? Our Branded Mini Games template not only offers engaging gameplay but also the option to customize existing games with your brand's identity. Whether it's a playful quiz or an exciting puzzle, we've got you covered. Elevate engagement, entertain, and make your brand a part of the fun. Discover the possibilities at stemmy.io now!",
  },
  {
    shortname: 'coupon-program',
    video: '',
    title: 'Gamified Coupon Program',
    description:
      'Introducing Our Playful Gamified Coupon Program: Save While Having Fun! Looking for a unique way to reward users? Our Gamified Coupon Program combines savings with a touch of whimsy. Cute emojis will cheer users on as they redeem coupons, creating an engaging and rewarding experience. Join us in adding a dash of playfulness to your promotions – explore stemmy.io to learn more!',
  },
  {
    shortname: 'ai-emoji-chatbot',
    video: 'TT6BYfjklSU',
    title: 'AI Emoji Chatbot',
    description:
      'Emoji AI Chatbot with Customizable Emojis. Are you looking to enhance user engagement on your website? Say hello to our Emoji AI Chatbot, where you have the power to select your preferred expressive emoji. Elevate user experience, infuse a touch of fun, and make each interaction uniquely yours. Explore the world of Emoji AI Chatbots at stemmy.io today!',
  },
];
