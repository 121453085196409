import {FlagComponent, US} from 'country-flag-icons/react/3x2';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BasicElement} from '../../helpers/BasicElement';
import {allFlags} from './AllFlags';

type FlagContext = {
  currentFlag: Flag;
  setFlag: (region: string, shortName: string) => void;
  getCurrentLocale: () => string;
};

export interface Flag {
  name: string;
  shortName: string;
  component: FlagComponent;
}

export const defaultFlag: Flag = {
  name: 'USA',
  component: US,
  shortName: 'US',
};

export const FlagContextElement = React.createContext<FlagContext>({
  currentFlag: defaultFlag,
  setFlag: () => {},
  getCurrentLocale: () => 'en',
});

const FlagKey = 'FlagKey';
export const API_REGION = process.env.REACT_APP_API_REGION;

const FlagProvider: React.FC<BasicElement> = ({children}) => {
  const [currentFlag, setCurrentFlag] = useState<Flag>(defaultFlag);
  const [currentLocale, setCurrentLocale] = useState<string>('en');

  const {i18n} = useTranslation();

  const getCurrentFlag = (region: string, country: string) => {
    return allFlags
      .filter((flag) => flag.region === region)[0]
      .countries.filter((flag) => flag.shortName === country)[0];
  };

  const setFlag = (region: string, shortName: string) => {
    setCurrentFlag(getCurrentFlag(region, shortName));
    localStorage.setItem(
      FlagKey,
      JSON.stringify({
        region,
        shortName,
      }),
    );
  };

  useEffect(() => {
    const prevFlag = localStorage.getItem(FlagKey);
    if (prevFlag) {
      if (API_REGION === 'RU') {
        setCurrentLocale('ru');
      } else {
        setCurrentLocale('en');
        const prevData = JSON.parse(prevFlag);
        setCurrentFlag(getCurrentFlag(prevData.region, prevData.shortName));
      }
    }
  }, []);

  // useEffect(() => {
  //   if (API_REGION === 'COM') {
  //     i18n.changeLanguage('en');
  //   } else {
  //     i18n.changeLanguage('ru');
  //   }
  // }, [currentFlag, i18n]);

  const getCurrentLocale = useCallback(() => {
    return currentLocale;
  }, [currentLocale]);

  useEffect(() => {
    i18n.changeLanguage(currentLocale);
  }, [currentLocale, i18n]);

  return (
    <FlagContextElement.Provider
      value={{
        currentFlag: currentFlag,
        setFlag,
        getCurrentLocale,
      }}
    >
      {children}
    </FlagContextElement.Provider>
  );
};

export const useLocalization = (): FlagContext => {
  const ctx = useContext(FlagContextElement);
  return ctx;
};

export default FlagProvider;
