import {useState} from 'react';
import ButtonBlack from '../buttonBlack/ButtonBlack';
import HeaderBlock from '../headerBlock/HeaderBlock';
import {dataPricing} from './PricingData';
import {Link} from 'react-router-dom';

const showBenefitsOnFolded = 2;

const PlansAndPricing: React.FC = () => {
  const [showPrices, setShowPrices] = useState(false);

  return (
    <div className="pt-3.5">
      <HeaderBlock
        id="pricing"
        text="Plans and pricing"
        className="whitespace-nowrap"
      />
      <div className="flex gap-4 md:gap-10 py-8 flex-wrap md:flex-nowrap">
        {dataPricing.map((price, index) => {
          return (
            <div
              key={index}
              className="px-[35px] py-[40px] rounded-[45px] border border-solid border-[#191A23] main-simple-shadow flex flex-col gap-7 w-full"
            >
              <div className="flex flex-col gap-2">
                <p className="font-spaceGrotesk text-4xl leading-[normal] font-medium">
                  {price.header}
                </p>
                <p className="font-spaceGrotesk">
                  <span className="text-2xl font-bold leading-[normal]">
                    {price.price ? '$' + price.price.annualy : 'Contact us'}
                  </span>
                  {price.price && (
                    <>
                      <span className="pl-1.5 text-lg font-medium leading-[normal]">
                        p/month
                      </span>
                      <div className="text-sm">Charged annualy</div>
                    </>
                  )}
                </p>
              </div>
              <div className="h-[1px] bg-black" />
              <div className="flex flex-col gap-[15px] pl-[25px]">
                {price.advantages
                  .slice(0, showPrices ? 100 : showBenefitsOnFolded)
                  .map((adv) => {
                    return (
                      <li
                        key={adv}
                        className="text-xl leading-[92%] tracking-[-0.4px]"
                      >
                        {adv}
                      </li>
                    );
                  })}
              </div>
              <Link to={price.link.annualy} className="mt-auto">
                <ButtonBlack
                  text="Buy"
                  className="mx-auto !w-full"
                  design={index === 1 ? 'black' : 'outline'}
                />
              </Link>
            </div>
          );
        })}
      </div>
      {!showPrices && (
        <ButtonBlack
          text="Compare Pricing"
          className="ml-auto"
          onClick={() => {
            setShowPrices((old) => !old);
          }}
        />
      )}
    </div>
  );
};

export default PlansAndPricing;
