import React from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import './BurgerMenu.scoped.css';

interface BurgerMenuProps extends React.HTMLProps<HTMLDivElement> {
  isOpen?: boolean;
  onClick?: () => void;
  classBar?: string;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  onClick,
  className,
  isOpen = false,
  classBar,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        'cursor-pointer aspect-square w-10 relative',
      )}
    >
      <motion.div
        layout
        layoutId="burgetMenu1"
        transition={{duration: 0.3, ease: 'easeOut'}}
        className={classNames(
          'bg-white rounded-full h-[5px] absolute pointer-events-none',
          classBar,
          isOpen ? 'w-8 left-[10%] top-[45%]' : 'top-[25%] right-[5%] w-[34px]',
        )}
        animate={{rotate: isOpen ? 45 : 0}}
      />
      <motion.div
        layout
        layoutId="burgetMenu2"
        transition={{duration: 0.3, ease: 'easeOut'}}
        className={classNames(
          'bg-white rounded-full h-[5px] absolute pointer-events-none',
          classBar,
          isOpen
            ? 'w-8 left-[10%] top-[45%]'
            : 'bottom-[25%] right-[5%] w-[25px]',
        )}
        animate={{rotate: isOpen ? -45 : 0}}
      />
    </div>
  );
};

export default BurgerMenu;
