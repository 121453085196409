import {Link} from 'react-router-dom';
import ButtonBlack from '../buttonBlack/ButtonBlack';
import WorkingCard from './WorkingCard';

const faqData = [
  {
    number: '01',
    title: 'How does it work?',
    text: 'After choosing the suitable gamified template, you add one line of code in the head section of your website.',
  },
  {
    number: '02',
    title: 'Can we customize the templates?',
    text: 'Absolutely! We understand that every company has its own branding and unique requirements. Our templates are fully customizable, allowing you to align them with your brand identity,',
  },
  {
    number: '03',
    title: 'How do we get started?',
    text: 'Simply explore our range of gamification templates on stemmy.io, select the ones that suit your goals, and follow the user-friendly setup instructions.',
  },
  {
    number: '04',
    title: 'How will it increase conversions?',
    text: 'Gamification has been proven to boost engagement, encourage user participation, and foster a sense of achievement. These factors often lead to increased conversions as users are more motivated to take desired actions.',
  },
  {
    number: '05',
    title: 'Do you provide analytics?',
    text: 'Yes, we offer robust analytics that allow you to track the performance of your gamification efforts. Monitor user interactions, progress, achievements, and other relevant metrics to assess the impact of the templates.',
  },
  {
    number: '06',
    title: 'Do you offer a chatbot option?',
    text: 'Some of our templates include interactive chatbot options that can guide users, answer questions, and enhance the overall user experience.',
  },
  {
    number: '07',
    title: 'Can we conduct A/B testing with gamification?',
    text: 'Absolutely. You can experiment with different templates, engagement mechanisms, and rewards to find what resonates best with your audience.',
  },
  {
    number: '08',
    title: 'Can we gamify a coupon program?',
    text: 'Yes, you can gamify coupon programs to make them more engaging. Our templates can help you create exciting coupon redemption experiences that keep users motivated to participate.',
  },
  {
    number: '09',
    title: 'Can we gamify a loyalty program?',
    text: 'Definitely. Our gamification templates are well-suited for creating engaging loyalty programs that encourage repeat interactions and customer retention.',
  },
  {
    number: '10',
    title: 'Do you have onboarding scenarios?',
    text: "Yes, we offer onboarding scenarios that make user orientation interactive and enjoyable. This helps users get familiar with your platform's features in a fun and engaging way.",
  },
  {
    number: '11',
    title: 'What are the results so far?',
    text: 'Our gamification templates have delivered significant results for many companies. These include increased engagement rates, improved user retention, higher conversion rates, and a more positive user experience overall.',
  },
  {
    number: '12',
    title: 'What are branded mini-games?',
    text: "Branded mini-games are interactive experiences that carry your company's identity and messaging. These games offer a unique way to engage users, promote your brand, and provide an enjoyable experience that aligns with your company's values.",
  },
  {
    number: '13',
    title:
      'Does this gamification work with the mobile version of the website?',
    text: 'Yes, our gamification templates are designed to be fully responsive and compatible with various devices, including mobile phones and tablets.',
  },
  {
    number: '14',
    title: 'Can you create custom gamification for my website?',
    text: "Absolutely. If our existing templates don't perfectly align with your vision, we offer custom gamification solutions tailored to your specific requirements. Contact our team to discuss your needs in detail.",
  },
  {
    number: '15',
    title: 'Does it matter what the website infrastructure is like?',
    text: 'No, it does not matter. Our gamification solutions are designed to be versatile and adaptable, making them compatible with a wide range of website infrastructures. Whether you have a simple website or a complex one, we can seamlessly integrate our gamification elements on top of it to enhance user engagement and interaction.',
  },
];
const WorkingProcess: React.FC = () => {
  return (
    <div className="py-5 lg:py-[40px]">
      <div className="flex flex-col gap-[30px] pt-5 lg:pt-[40px]">
        {faqData.map((data, index) => {
          return (
            <WorkingCard
              key={index}
              title={data.title}
              text={data.text}
              number={data.number}
            />
          );
        })}
      </div>
      <Link to="/templates">
        <ButtonBlack text="See all features" className="mx-auto mt-[68px]" />
      </Link>
    </div>
  );
};

export default WorkingProcess;
