import {useCallback, useEffect} from 'react';
import PlansAndPricing from '../../components/simpleMainPage/plansAndPricing/PlansAndPricing';
import Templates from '../../components/simpleMainPage/templates/Templates';
import WellKnownCompanies from '../../components/simpleMainPage/wellKnownCompanies/WellKnownCompanies';
import WhatGamifyToday from '../../components/simpleMainPage/whatGamifyToday/WhatGamifyToday';
import WorkingProcess from '../../components/simpleMainPage/workingProcess/WorkingProcess';
import {useRequestDemo} from '../../app/layouts/simpleLayout.tsx/SimpleLayout';
import {useLocation} from 'react-router-dom';

const SimpleMainPage: React.FC = () => {
  const {setRequestDemoOpened} = useRequestDemo();

  const onRequestDemo = useCallback(() => {
    setRequestDemoOpened(true);
  }, [setRequestDemoOpened]);

  const {hash} = useLocation();

  useEffect(() => {
    if (hash) {
      const violation = document.getElementById(hash.slice(1, hash.length));
      if (violation)
        window.scrollTo({
          top: violation.offsetTop,
          behavior: 'smooth',
        });
    }
  }, [hash]);

  return (
    <>
      <WhatGamifyToday requestDemo={onRequestDemo} />
      <WellKnownCompanies requestDemo={onRequestDemo} />
      <Templates />
      <PlansAndPricing />
      <WorkingProcess />
    </>
  );
};

export default SimpleMainPage;
