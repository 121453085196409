import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useOutletContext} from 'react-router-dom';
import Footer from '../../../components/simpleMainPage/footer/Footer';
import Header from '../../../components/simpleMainPage/header/Header';
import ContactSalesPopupWrapper from '../../../components/contactSalesPopup/ContactSalesPopupWrapper';

type ContextType = {setRequestDemoOpened: (val: boolean) => void | null};

const SimpleLayout: React.FC = () => {
  const [isRequestDemoOpened, setRequestDemoOpened] = useState(false);

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="flex flex-col justify-between max-w-full overflow-hidden font-spaceGrotesk">
      <div className="max-w-[1440px] text-black px-2 md:px-10 lg:px-[100px] w-full mx-auto">
        <Header setRequestDemoOpened={setRequestDemoOpened} />
        <Outlet context={{setRequestDemoOpened}} />
        <Footer />
        <ContactSalesPopupWrapper
          isOpened={isRequestDemoOpened}
          onClosed={() => setRequestDemoOpened(false)}
        />
      </div>
    </div>
  );
};

export function useRequestDemo() {
  return useOutletContext<ContextType>();
}

export default SimpleLayout;
