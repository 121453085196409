interface DataPricing {
  header: string;
  price?: {
    monthly: number;
    annualy: number;
  };
  advantages: string[];
  link: {
    monthly: string;
    annualy: string;
  };
}

export const dataPricing: DataPricing[] = [
  {
    header: 'Stemmy Basic',
    price: {
      monthly: 199,
      annualy: 135,
    },
    advantages: [
      'Basic integration for onboarding request',
      'Analytics Dashboard',
      '10 Basic Gamify Effects',
    ],
    link: {
      monthly: 'https://buy.stripe.com/9AQdUrbzI4Np7vybJ2',
      annualy: 'https://buy.stripe.com/14k3fNdHQbbN4jm00l',
    },
  },
  {
    header: 'Stemmy Pro',
    price: {
      monthly: 299,
      annualy: 225,
    },
    advantages: [
      'Unlimited number of onboarding scenarios',
      'Analytics Dashboard',
      'unlimited number of effects',
    ],
    link: {
      monthly: 'https://buy.stripe.com/5kA2bJ0V42Fh7vy14n',
      annualy: 'https://buy.stripe.com/fZe7w39rA3Jl7vyaEW',
    },
  },
  {
    header: 'Enterprise',
    advantages: [
      'Unlimited templates',
      'Analytics Dashboard',
      'Gamification by request',
      'Metaverse Integration',
    ],
    link: {
      monthly: 'https://buy.stripe.com/9AQdUrbzI4Np7vybJ2',
      annualy: 'https://buy.stripe.com/14k3fNdHQbbN4jm00l',
    },
  },
];
