import YouTube from 'react-youtube';
import HeaderBlock from '../headerBlock/HeaderBlock';
import SmallHeaderBlock from '../smallHeaderBlock/SmallHeaderBlock';
import {VideoDataElement} from '../../../pages/videoPage/VideoData';
import {Link} from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import {AllTemplates} from '../templatesBlock/TemplatesData';
import ButtonBlack from '../buttonBlack/ButtonBlack';

export interface VideoBlockProps {
  data: VideoDataElement;
  onRequestDemo: () => void;
}

const VideoBlock: React.FC<VideoBlockProps> = ({
  data: {title, video, description},
  onRequestDemo,
}) => (
  <>
    <HeaderBlock text={title} className="pb-10" />
    {video && video !== '' && (
      <YouTube
        videoId={video}
        className="aspect-video w-full "
        iframeClassName="rounded-lg"
      />
    )}

    <SmallHeaderBlock text={title} />
    <p className="text-base lg:text-lg leading-[normal] font-spaceGrotesk">
      {description}
    </p>
    <ButtonBlack
      text="Gamify your website now!"
      className="mt-8"
      onClick={() => onRequestDemo()}
    />

    <SmallHeaderBlock text="You might also like:" />
    <ScrollContainer
      className="mt-3 scroll-container cursor-pointer flex gap-5 lg:gap-10 scroll-smooth transition duration-100"
      vertical={false}
    >
      {AllTemplates[0].options.map((t, i) => {
        return (
          <Link to={`/template/${t.link}`}>
            <div
              className="flex flex-col gap-6 min-w-[200px] md:min-w-[300px] lg:min-w-[530px]"
              key={i}
            >
              <img src={t.image} alt={t.label} />
              <h4 className="text-lg md:text-2xl lg:text-[30px] font-medium leading-[normal] px-[7px]">
                {t.label}
              </h4>
            </div>
          </Link>
        );
      })}
    </ScrollContainer>
  </>
);

export default VideoBlock;
