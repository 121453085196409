import i18n from 'i18next';

import testEN from './en/test.json';
import commonEN from './en/common.json';
import simulationsPageEN from './en/simulations-page.json';
import headerEN from './en/header.json';
import footerEN from './en/footer.json';

import testRU from './ru/test.json';
import commonRU from './ru/common.json';
import simulationsPageRU from './ru/simulations-page.json';
import headerRU from './ru/header.json';
import footerRU from './ru/footer.json';

import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import {API_REGION} from '../../services/flagProvider/FlagProvider';

export const resources = {
  en: {
    test: testEN,
    common: commonEN,
    simulationsPage: simulationsPageEN,
    header: headerEN,
    footer: footerEN,
  },
  ru: {
    test: testRU,
    common: commonRU,
    simulationsPage: simulationsPageRU,
    header: headerRU,
    footer: footerRU,
  },
} as const;

i18n
  .use(detector)
  .use(initReactI18next)

  .init({
    lng: API_REGION === 'RU' ? 'ru' : undefined,
    fallbackLng: 'en',
    ns: ['test', 'common', 'simulationsPage', 'header', 'footer'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
