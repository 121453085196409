import classNames from 'classnames';

export interface HeaderBlockProps extends React.HTMLProps<HTMLHeadingElement> {
  text: string;
}

const SmallHeaderBlock: React.FC<HeaderBlockProps> = ({
  text,
  className,
  ...rest
}) => {
  return (
    <h3
      className={classNames(
        'text-2xl lg:text-[30px] leading-[normal] font-medium pb-3 pt-6',
        className,
      )}
      {...rest}
    >
      {text}
    </h3>
  );
};

export default SmallHeaderBlock;
