import YouTube from 'react-youtube';
import HeaderBlock from '../headerBlock/HeaderBlock';
import amazon from '../../../assets/simpleMainPage/companies/amazon.png';
import dribbble from '../../../assets/simpleMainPage/companies/dribbble.png';
import hubspot from '../../../assets/simpleMainPage/companies/hubspot.png';
import netflix from '../../../assets/simpleMainPage/companies/netflix.png';
import notion from '../../../assets/simpleMainPage/companies/notion.png';
import zoom from '../../../assets/simpleMainPage/companies/zoom.png';
import ButtonBlack from '../buttonBlack/ButtonBlack';

interface WellKnownCompaniesProps {
  requestDemo: () => void;
}

const WellKnownCompanies: React.FC<WellKnownCompaniesProps> = ({
  requestDemo,
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 lg:gap-[50px]">
      <HeaderBlock text="Well-known companies trust us" className="lg:pt-4" />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 w-full grayscale justify-items-center">
        <img src={amazon} alt="amazon" />
        <img src={dribbble} alt="dribbble" />
        <img src={hubspot} alt="hubspot" />
        <img src={netflix} alt="netflix" />
        <img src={notion} alt="notion" />
        <img src={zoom} alt="zoom" />
      </div>
      <div className="w-full flex-col lg:flex-row gap-[30px] pl-[15px] md:pl-[30px] lg:pl-[60px] pr-[15px] md:pr-[30px] py-8 flex items-center justify-between rounded-[45px] bg-[#F3F3F3]">
        <div className="flex flex-col gap-[26px] w-full lg:w-[45%]">
          <div className="text-3xl leading-[normal] font-medium">
            Ready-made designs
          </div>
          <div className="text-lg leading-[normal]">
            Ready-made game scenarios and designs. <br />
            Benefit from thousands world most engaging game mechanics and
            customize existing templates. <br />
            Characters, leaderboards, loyalty points, collaborative tasks, and
            quality 3D effects to choose from.
          </div>
          <ButtonBlack
            text="Gamify the website"
            onClick={() => requestDemo()}
          />
        </div>
        <div
          id="video"
          className="overflow-clip rounded-[32px] w-full lg:w-[42%]"
        >
          <YouTube videoId="7ZKcLM7WpuE" className="aspect-video w-full" />
        </div>
      </div>
    </div>
  );
};

export default WellKnownCompanies;
