import {useEffect, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';

interface VideoPopupProps {
  youtubeId: string;
  onClosed: () => void;

  isOpen: boolean;
}

const VideoPopup: React.FC<VideoPopupProps> = ({
  youtubeId,
  isOpen,
  onClosed,
}) => {
  const [isOpened, setIsOpened] = useState(isOpen);

  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]);

  return (
    <Popup
      //   trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{
        padding: '15px',
        borderRadius: '0.375rem',
        width: '100vw',
        maxWidth: '1200px',
        position: 'relative',
      }}
      onClose={onClosed}
      modal
      nested
      open={isOpened}
      closeOnDocumentClick={true}
    >
      <div className="bg-[#1a1a1a] rounded-[32px] p-4">
        <div
          onClick={() => onClosed()}
          className="p-2 absolute right-6 top-6 bg-gray-500/[.20] hover:bg-gray-500/[.60] cursor-pointer rounded-xl"
        >
          <AiOutlineClose className="text-white " size={30} />
        </div>

        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          className="w-full aspect-video rounded-[32px]"
          allowFullScreen
        ></iframe>
      </div>
    </Popup>
  );
};

export default VideoPopup;
