import HeaderBlock from '../headerBlock/HeaderBlock';
import {TemplateFullInfo} from './TemplatesData';
import CardTemplate from './CardTemplate';
import ButtonBlack from '../buttonBlack/ButtonBlack';
import {Link} from 'react-router-dom';

const Templates: React.FC = () => {
  return (
    <div className="pt-3.5">
      <HeaderBlock
        id="templates"
        text="Templates for absolutely any onboarding!"
        className="lg:whitespace-nowrap"
      />
      <div className="grid md:grid-cols-2 gap-4 lg:gap-10 pt-5 lg:pt-[68px]">
        {TemplateFullInfo.slice(0, 4).map((template, index) => {
          return (
            <CardTemplate
              key={index}
              header={template.header}
              description={template.description}
              image={template.image}
              video={template.video}
              fullVideoId={template.fullVideoId}
            />
          );
        })}
      </div>
      {TemplateFullInfo.length >= 4 && (
        <div className="pt-4 lg:pt-10">
          <CardTemplate
            header={TemplateFullInfo[4].header}
            description={TemplateFullInfo[4].description}
            image={TemplateFullInfo[4].image}
            video={TemplateFullInfo[4].video}
            fullVideoId={TemplateFullInfo[4].fullVideoId}
            isBigCard
          />
        </div>
      )}
      <Link to="/templates">
        <ButtonBlack
          text="Browse all templates"
          // design="accent"
          className="mt-10 mx-auto"
        />
      </Link>
    </div>
  );
};

export default Templates;
