import classNames from 'classnames';
import {useState} from 'react';
import {motion} from 'framer-motion';

export interface WorkingCardProps {
  title: string;
  text: string;
  number: string;
  className?: string;
}

const WorkingCard: React.FC<WorkingCardProps> = ({
  title,
  text,
  number,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      animate={{
        background: isOpen ? '#191A23' : '#F3F3F3',
      }}
      //   layout
      transition={{duration: 0.3}}
      className={classNames(
        'cursor-pointer px-4 md:px-[60px] py-2 md:py-[41px] rounded-[45px] border border-solid border-[#191A23] main-simple-shadow',
        isOpen ? 'text-white' : 'text-black',
        className,
      )}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3 md:gap-[25px] max-w-[80%]">
          <span className="text-xl md:text-[60px] font-medium leading-[normal]">
            {number}
          </span>
          <span className="text-lg md:text-3xl font-medium leading-[normal]">
            {title}
          </span>
        </div>
        {isOpen ? (
          <svg
            width="58"
            height="59"
            viewBox="0 0 58 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="29"
              cy="29.5"
              r="28.5"
              fill="#F3F3F3"
              stroke="#191A23"
            />
            <path d="M20 32.14V26.5H37.76V32.14H20Z" fill="black" />
          </svg>
        ) : (
          <svg
            width="58"
            height="59"
            viewBox="0 0 58 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="29"
              cy="29.5001"
              r="28.5"
              fill="#F3F3F3"
              stroke="#191A23"
            />
            <path
              d="M25.6 41.5801V31.8601H16V26.2201H25.6V16.5001H31.48V26.2201H41.08V31.8601H31.48V41.5801H25.6Z"
              fill="#191A23"
            />
          </svg>
        )}
      </div>

      <motion.div
        animate={{height: isOpen ? 'auto' : '0px'}}
        initial={{height: '0px'}}
        transition={{duration: 0.3}}
        className="overflow-hidden"
      >
        <hr className="h-[1px] bg-white my-4 md:my-[30px]" />

        <p className="font-spaceGrotesk text-lg leading-[normal] font-normal">
          {text}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default WorkingCard;
