import {createContext, useCallback, useContext, useState} from 'react';
import Popup from 'reactjs-popup';
import FormButton from '../../components/form/button/FormButton';
import {BasicElement} from '../../helpers/BasicElement';

interface ConfirmationAlertProps {
  message: string;

  onConfirm: () => void;
  onCancel: () => void;
}

type ConfirmationAlertContext = {
  showAlert: (params: ConfirmationAlertProps) => void;
};

export const ConfirmationAlertContextElement =
  createContext<ConfirmationAlertContext>({
    showAlert: () => {},
  });

const ConfirmationAlertProvider: React.FC<BasicElement> = ({children}) => {
  const [confirmationParams, setConfirmationParams] = useState<
    ConfirmationAlertProps | undefined
  >();
  const [opened, setOpened] = useState(false);

  const showAlert = useCallback((params: ConfirmationAlertProps) => {
    console.log('Opening alert');
    setConfirmationParams(params);
    setOpened(true);
  }, []);

  const onConfirmClick = useCallback(() => {
    if (confirmationParams) confirmationParams.onConfirm();
    setOpened(false);
  }, [confirmationParams]);

  const onCancelClick = useCallback(() => {
    if (confirmationParams) confirmationParams.onCancel();
    setOpened(false);
  }, [confirmationParams]);

  return (
    <ConfirmationAlertContextElement.Provider
      value={{
        showAlert,
      }}
    >
      <Popup
        contentStyle={{
          padding: '15px',
          borderRadius: '0.375rem',
          maxWidth: '400px',
        }}
        modal
        nested
        open={opened}
        closeOnDocumentClick={false}
      >
        <p className="text-xl text-center">{confirmationParams?.message}</p>
        <div className="flex gap-2 text-xl w-full mt-4">
          <FormButton className="flex-1" onClick={onConfirmClick}>
            Ok
          </FormButton>
          <FormButton className="flex-1" onClick={onCancelClick}>
            Cancel
          </FormButton>
        </div>
      </Popup>
      {children}
    </ConfirmationAlertContextElement.Provider>
  );
};

export const useConfirmationAlert = (): ConfirmationAlertContext => {
  const ctx = useContext(ConfirmationAlertContextElement);
  return ctx;
};

export default ConfirmationAlertProvider;
