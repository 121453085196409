import classNames from 'classnames';

export interface ButtonBlackProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  design?: 'black' | 'outline' | 'accent';
}

const ButtonBlack: React.FC<ButtonBlackProps> = ({
  text,
  className,
  design = 'black',
  ...rest
}) => {
  return (
    <button
      className={classNames(
        'py-5 px-[35px] rounded-[14px] text-center text-xl whitespace-nowrap cursor-pointer w-fit',
        design === 'black' && 'bg-[#191A23] text-white hover:bg-[#CF7BFD]',
        design === 'accent' && 'bg-[#CF7BFD] text-white hover:bg-[#CF7BFD]/70',
        design === 'outline' &&
          'border-2 border-solid border-[#191A23] terxt-[#191A23] hover:bg-[#191A23] hover:text-white',
        className,
      )}
      {...rest}
    >
      {text}
    </button>
  );
};

export default ButtonBlack;
