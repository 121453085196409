import what_will_you_gamify_today from '../../../assets/simpleMainPage/what_will_you_gamify_today.png';
import what_will_you_gamify_today_mobile from '../../../assets/simpleMainPage/what_will_you_gamify_today_mobile.png';
import HeaderBlock from '../headerBlock/HeaderBlock';
import ButtonBlack from '../buttonBlack/ButtonBlack';
import {isMobile} from 'react-device-detect';

interface WhatGamifyTodayProps {
  requestDemo: () => void;
}

const WhatGamifyToday: React.FC<WhatGamifyTodayProps> = ({requestDemo}) => {
  return (
    <div className="flex flex-col items-center">
      <HeaderBlock text="What will you gamify today?" />
      <h3 className="leading-[normal] text-lg md:text-2xl lg:text-[32px] text-[#B1B1B1] pt-3 pb-9 text-center">
        We provide companies with no-code templates to build engaging
        onboarding.
      </h3>
      <ButtonBlack
        text="Request a demo"
        design="outline"
        onClick={() => requestDemo()}
      />
      <div className="relative aspect-[124/70] w-full pointer-events-none">
        {/* <div className="w-[14%] aspect-square bg-red-300 opacity-30 absolute -top-[10%] left-[10%] z-50">
          emoji placeholder
        </div> */}
        {/* <div className="bg-red-300 opacity-30 absolute right-[2%] top-[1%] w-[13.5%] h-[49%] z-50">
          phone placeholder
        </div> */}
        {/* <div className="bg-red-300 opacity-30 absolute right-[6%] top-0 w-[15%] h-[53%] z-50">
          mobile phone placeholder
        </div>
        <div className="w-[14%] aspect-square bg-red-300 opacity-30 absolute -top-[10%] left-[10%] z-50">
          mobile emoji placeholder
        </div> */}
        <img
          src={
            isMobile
              ? what_will_you_gamify_today_mobile
              : what_will_you_gamify_today
          }
          alt="Safari Lightpic for gaming block"
          className="absolute top-[-13%]"
        />
      </div>
    </div>
  );
};

export default WhatGamifyToday;
