import classNames from 'classnames';

export interface HeaderBlockProps extends React.HTMLProps<HTMLHeadingElement> {
  text: string;
}

const HeaderBlock: React.FC<HeaderBlockProps> = ({
  text,
  className,
  ...rest
}) => {
  return (
    <h2
      className={classNames(
        'text-2xl md:text-5xl lg:text-[64px] leading-[92%] font-bold pt-4 lg:pt-10 text-center',
        className,
      )}
      {...rest}
    >
      {text}
    </h2>
  );
};

export default HeaderBlock;
