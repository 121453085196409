import {Routes, Route, BrowserRouter} from 'react-router-dom';
import SimpleLayout from '../layouts/simpleLayout.tsx/SimpleLayout';
import SimpleMainPage from '../../pages/simpleMainPage/SimpleMainPage';
import VideoPage from '../../pages/videoPage/VideoPage';
import TemplatesPage from '../../pages/templatesPage/TemplatesPage';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      {/* <Route path="/">
        <Route element={<BasicLayout />}>
          <Route index element={<Main />} />
        </Route>
      </Route> */}
      <Route path="/">
        <Route element={<SimpleLayout />}>
          <Route index element={<SimpleMainPage />} />
        </Route>
      </Route>
      <Route element={<SimpleLayout />}>
        <Route path="/template/:shortName">
          <Route index element={<VideoPage />} />
        </Route>
        <Route path="/templates">
          <Route index element={<TemplatesPage />} />
        </Route>
      </Route>

      {/* <Route path="/tournaments">
        <Route
          path="/tournaments/dexilon"
          element={
            <Redirect href="https://testnet.dexilon.io/swaps/eth_usdt" />
          }
        />
      </Route> */}
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
