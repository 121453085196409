import {AiOutlineTwitter} from 'react-icons/ai';
import {BiLogoInstagram} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import ButtonBlack from '../buttonBlack/ButtonBlack';

const Footer: React.FC = () => (
  <div className="pt-7 text-white">
    <div className="pt-5 md:pt-[55px] px-6 md:px-[60px] pb-5 md:pb-[50px] flex flex-col gap-4 md:gap-[50px] rounded-[45px_45px_0px_0px] bg-[#F3F3F3] text-[#292A32] border border-solid">
      <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-[155px]">
        <div className="flex gap-4 lg:gap-[40px] text-lg underline leading-[normal] flex-wrap">
          <Link to="/templates">Templates</Link>
          <Link to="/#pricing">Pricing</Link>
          <Link to="/?request-demo">Request a demo</Link>
        </div>

        <div className="flex gap-[20px]">
          {/* <a href="#" target="_blank">
              <BiLogoTelegram
                size={30}
                className="bg-black text-white rounded-full p-1 hover:bg-black/70"
              />
            </a> */}
          <a href="https://www.instagram.com/stemmy.io" target="_blank">
            <BiLogoInstagram
              size={30}
              className="text-white rounded-full p-1 bg-black hover:bg-black/70"
            />
          </a>
          <a href="https://twitter.com/stemmy_io" target="_blank">
            <AiOutlineTwitter
              size={30}
              className="bg-black text-white rounded-full p-1 hover:bg-black/70"
            />
          </a>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-4 lg:gap-[154px] w-full">
        <div className="flex flex-col gap-[27px]">
          <h2 className="rounded-[7px] bg-[#CF7BFD] px-[7px] text-xl font-medium leading-[normal] text-white w-fit">
            Contact us:
          </h2>
          <div className="flex flex-col gap-[20px] text-lg leading-[normal] font-normal">
            <a href="mailto:content@stemmy.io">Email: content@stemmy.io</a>
            {/* <a href="tel:+5555678901">Phone: 555-567-8901</a> */}
            <div>
              Address: 919 North Market Street <br />
              Wilmington, DE 19801
            </div>
          </div>
        </div>

        <div className="border border-solid rounded-[14px] p-5 grow flex items-center">
          <ButtonBlack
            text="Request a demo"
            design="accent"
            className="h-fit grow"
          />
        </div>

        {/* <form
            className="flex flex-col md:flex-row justify-between gap-[20px] px-4 lg:px-[40px] py-4 lg:py-[58px] rounded-[14px] bg-[#F3F3F3] w-full lg:w-[60%] h-fit border border-solid"
            onSubmit={(e) => {
              e.preventDefault();
              onSubscribe();
            }}
          >
            <div className="rounded-[14px] border border-solid border-black px-4 lg:px-[35px] py-2 lg:py-[22px] w-full flex items-center">
              <input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent placeholder:text-[#292A32] placeholder:text-lg placeholder:leading-[normal]"
              />
            </div>
            <button
              type="submit"
              className="bg-[#CF7BFD] rounded-[14px] px-[35px] py-[20px] text-xl text-black w-full whitespace-nowrap cursor-pointer"
            >
              Subscribe to news
            </button>
          </form> */}
      </div>

      <hr className="h-[1px] bg-[#292A32]" />

      <div className="flex flex-col md:flex-row gap-4 md:gap-[40px] text-lg">
        <span>© 2023 Positivus. All Rights Reserved.</span>
        <Link to="/privacy" className="underline">
          Privacy Policy
        </Link>
      </div>
    </div>
  </div>
);

export default Footer;
