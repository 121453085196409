import classNames from 'classnames';
import './Button.css';

interface ButtonStyleDef {
  common: string;
  disabled: string;
  normal: string;
}

interface ButtonStyleObject {
  [name: string]: ButtonStyleDef;
}

export const ButtonStyle: ButtonStyleObject = {
  Rainbow: {
    common:
      'text-xl px-6 rounded-full colored font-semibold text-center no-underline',
    disabled: 'text-gray-300 opacity-80',
    normal: 'text-white hover:contrast-150 transition-all duration-300',
  },
  Gray: {
    common:
      'text-md py-2 px-4 rounded-full font-semibold gray text-center no-underline',
    disabled: '',
    normal: 'text-white',
  },
  Lightgray: {
    common:
      'text-xl py-3 px-6 rounded-full font-semibold lightgray text-center no-underline ',
    disabled: '',
    normal: 'text-white',
  },
  Transparent: {
    common: 'py-2 px-4 font-semibold bg-transparent',
    disabled: '',
    normal: 'hover:text-white/80',
  },
  Round: {
    common:
      'aspect-square h-full text-2xl p-2 rounded-full font-semibold gray text-center no-underline',
    disabled: '',
    normal: 'text-white',
  },
  White: {
    common: 'bg-white font-semibold text-[#bd00ff] text-xl rounded-full',
    disabled: '',
    normal: 'hover:text-[#8300b3]',
  },
};

type ButtonProps = {
  buttonStyle?: ButtonStyleDef;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<ButtonProps> = ({
  className,
  type = 'button',
  children,
  disabled = false,
  buttonStyle = ButtonStyle.Rainbow,
  ...rest
}) => (
  <button
    type={type}
    className={classNames(
      'flex justify-center items-center',
      buttonStyle.common,
      disabled ? buttonStyle.disabled : buttonStyle.normal,
      className,
    )}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
