import {AnimatePresence, motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import {BasicElement} from '../../helpers/BasicElement';
import ContactSalesPopup from './ContactSalesPopup';

interface ContactSalesPopupProps extends BasicElement {
  onOpened?: () => void;
  onClosed?: () => void;
  isOpened?: boolean;
}

export type ContactSalesFormStates = 'default' | 'loading' | 'done';

const ContactSalesPopupWrapper: React.FC<ContactSalesPopupProps> = ({
  onOpened,
  onClosed,
  isOpened = false,
  children,
}) => {
  const [openPopup, setOpenPopup] = useState(isOpened);
  const [formState, setFormState] = useState<ContactSalesFormStates>('default');

  useEffect(() => {
    setOpenPopup(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (openPopup) {
      setFormState('default');
      if (onOpened) onOpened();
    }
    if (!openPopup && onClosed) onClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup]);

  return (
    <>
      <div onClick={() => setOpenPopup(true)}>{children}</div>
      <AnimatePresence>
        {openPopup && (
          <>
            <div
              className="fixed bg-[rgba(0,_0,_0,_0.8)] w-screen h-screen top-0 left-0 z-[55]"
              onClick={() => setOpenPopup(false)}
            ></div>
            <div className="place-content-center flex items-center flex-nowrap gap-[10px] h-full left-0 p-0 fixed top-0 w-full z-[55] pointer-events-none">
              <motion.div
                animate={{y: ['30%', '0%']}}
                exit={{y: ['0%', '30%']}}
                transition={{duration: 0.4}}
                className="container-content mx-4"
              >
                <div className="popup-contact-sales">
                  <div className="flex flex-wrap-reverse w-full">
                    {/* <img
                      src="./assets/planet-graph.png"
                      className="w-full block max-w-[253px] rotate-[8deg]"
                    /> */}

                    <div
                      className="ml-auto aspect-square bg-[rgba(255,_255,_255,_0.05)] rounded-[21px] cursor-pointer overflow-hidden relative w-[42px] h-[42px] will-change-transform"
                      onClick={() => setOpenPopup(false)}
                    >
                      <div className="rotate-45 bg-[rgb(104,_104,_104)] rounded-[4px] h-[4px] left-[calc(50%_-_12px)] overflow-hidden absolute top-[calc(50%_-_2px)] w-[24px] will-change-transform" />
                      <div className="-rotate-45 bg-[rgb(104,_104,_104)] rounded-[4px] h-[4px] left-[9px] overflow-hidden absolute top-[19px] w-[24px] will-change-transform" />
                    </div>
                  </div>

                  <ContactSalesPopup
                    state={formState}
                    setFormState={setFormState}
                    closeRequest={() => setOpenPopup(false)}
                  />
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ContactSalesPopupWrapper;
