import {useCallback, useEffect, useState} from 'react';
import VideoBlock from '../../components/simpleMainPage/videoBlock/VideoBlock';
import {VideoDataElement, videoData} from './VideoData';
import {useParams} from 'react-router-dom';
import {useRequestDemo} from '../../app/layouts/simpleLayout.tsx/SimpleLayout';

const VideoPage: React.FC = () => {
  const params = useParams<{shortName: string}>();

  const [templateData, setTemplateData] = useState<VideoDataElement>();

  const getData = useCallback((shortname: string) => {
    const data = videoData.find((x) => x.shortname === shortname);
    return data;
  }, []);

  useEffect(() => {
    if (!params.shortName) return;
    setTemplateData(getData(params.shortName));
  }, [getData, params.shortName]);

  const {setRequestDemoOpened} = useRequestDemo();

  const onRequestDemo = useCallback(() => {
    setRequestDemoOpened(true);
  }, [setRequestDemoOpened]);

  if (!params.shortName || !templateData) return <p>Not found</p>;
  return <VideoBlock data={templateData} onRequestDemo={onRequestDemo} />;
};

export default VideoPage;
