import emoji1 from '../../../assets/simpleMainPage/templates/option-emoji-01.png';
import emoji2 from '../../../assets/simpleMainPage/templates/option-emoji-02.png';
import emoji3 from '../../../assets/simpleMainPage/templates/option-emoji-03.png';
import leaderboard1 from '../../../assets/simpleMainPage/templates/option-leaderboard-01.png';
import leaderboard2 from '../../../assets/simpleMainPage/templates/option-leaderboard-02.png';
import leaderboard3 from '../../../assets/simpleMainPage/templates/option-leaderboard-03.png';
import loyalty1 from '../../../assets/simpleMainPage/templates/option-loyalty-01.png';
import loyalty2 from '../../../assets/simpleMainPage/templates/option-loyalty-02.png';
import loyalty3 from '../../../assets/simpleMainPage/templates/option-loyalty-03.png';

interface DataTemplate {
  title: string;
  //url: string;
  options: {
    label: string;
    image: any;
    link: string;
  }[];
}

export const AllTemplates: DataTemplate[] = [
  {
    title: 'Loyalty program',
    options: [
      {
        label: 'Boost Loyalty Program',
        image: emoji1,
        link: 'boost-loyalty-program',
      },
      {label: 'Leaderboards', image: leaderboard1, link: 'leaderboards'},
      {label: 'Daily Quests', image: loyalty1, link: 'daily-quests'},
      {
        label: 'Host Thrilling Tournaments',
        image: loyalty2,
        link: 'thrilling-tournaments',
      },
      {
        label: 'Progress Bars + Emoji for Travel',
        image: loyalty3,
        link: 'for-travel',
      },
      {
        label: 'Emoji Tamagotchi Gamification',
        image: emoji2,
        link: 'tamagotchi',
      },
      {label: '3D Gamified Task Cards', image: emoji2, link: 'task-cards'},
    ],
  },
  {
    title: 'Gamified Forms',
    options: [
      {
        label: 'Gamified Forms and Quizzes',
        image: emoji3,
        link: 'forms-and-quizzes',
      },
      {label: 'Gamified Forms for HR', image: emoji2, link: 'forms-for-hr'},
    ],
  },
  {
    title: 'Onboarding',
    options: [
      {
        label: 'Animated emoji',
        image: emoji2,
        link: 'animated-emoji',
      },
      {
        label: 'Interactive 3D Guide',
        image: emoji1,
        link: 'interactive-3d-guide',
      },
      {
        label: 'Emoji Tamagotchi Gamification',
        image: emoji3,
        link: 'tamagotchi',
      },
      {
        label: 'Gamified Onboarding for Edutech',
        image: emoji2,
        link: 'onboarding-edutech',
      },
      {
        label: 'Onboarding for WEB3 companies',
        image: emoji2,
        link: 'onboarding-web3',
      },
    ],
  },
  {
    title: 'Entertainment',
    options: [
      {
        label: 'Slot Machine',
        image: emoji1,
        link: 'slot-machine',
      },
      {label: 'Branded Mini Games', image: emoji2, link: 'mini-games'},
      {label: '3D Gamified Task Cards', image: emoji2, link: 'task-cards'},
    ],
  },
  {
    title: 'Gamified Coupons',
    options: [
      {
        label: 'Gamified Coupon Program',
        image: emoji1,
        link: 'coupon-program',
      },
      {
        label: 'Emoji Tamagotchi Gamification',
        image: emoji2,
        link: 'tamagotchi',
      },
    ],
  },
  {
    title: 'Gamified Chatbots',
    options: [
      {
        label: 'AI Emoji Chatbot',
        image: emoji1,
        link: 'ai-emoji-chatbot',
      },
      {
        label: 'Emoji Tamagotchi Gamification',
        image: emoji2,
        link: 'tamagotchi',
      },
    ],
  },
];
