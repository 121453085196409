import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortMutationOptions} from '../../helpers/ReactQueryOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {CourseDTO} from '../courses/dto/CourseDTO';
import {SignUpDAO} from './dao/SignUpDAO';

const UploadNewImage = 'signup/send';

export const useContactUs = (
  errorHandler?: (error: AxiosError<ApiError>) => void,
  options?: UseShortMutationOptions<void, AxiosError<ApiError>, SignUpDAO>,
) => {
  return useMutation<void, AxiosError<ApiError>, SignUpDAO>(
    [UploadNewImage],
    async (contactUsForm: SignUpDAO): Promise<void> => {
      await APIRequest.post<CourseDTO[]>(
        `/SignupForm/ContactUsForm`,
        contactUsForm,
      );
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
