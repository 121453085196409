/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React from 'react';

type FormButtonProps = {selected?: boolean} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const FormButton: React.FC<FormButtonProps> = ({
  className,
  type = 'button',
  children,
  selected = false,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        'text-lg p-3 rounded-lg',
        disabled ? 'bg-gray-400 text-gray-700' : 'bg-[#5F38C2] text-white',
        selected && 'opacity-80',
        'font-semibold text-center no-underline hover:opacity-80 ease-in-out duration-300',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
export default FormButton;
